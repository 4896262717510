div.mentions-legales {
    font-family: sans-serif;
}

div.mentions-legales h1 {
    font-size: 200%;
    text-align: center;
}

div.mentions-legales p {
    text-align: justify;
    font-size: 14px;
}

/**  list-style-type: none; */
div.mentions-legales h2 {
    font-size: 150%;
    padding-top: 30px;
    color: gray;
}

div.mentions-legales h3 {
    font-size: 125%;
    text-transform: none;
    text-shadow: none;
    letter-spacing: normal;
}
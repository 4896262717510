.layout-footer {
    background-color: rgb(28, 35, 49);
    margin-top: 3rem;
    height: 6rem;
}

.layout-footer ul {
    list-style: none;
}
.layout-footer li {
    margin-right: 1.5rem;
}

html,body {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	background-color: #F4F4F4;
	font-family: 'Roboto', sans-serif;
}

@font-face {
	font-family: 'Bangers-Regular';
	font-style: normal;
	font-weight: normal;
	/*src: url('../fonts/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Bangers-Regular'),
	/*url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	/*url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	/*url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('./assets/fonts/Bangers-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
	/*url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
.white-text{
	color: #FFFFFF;
}
.text-center {
	text-align: center;
}
.bold-text{
	font-weight: bold;
}
